<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Compras</h4>
            <div class="small text-muted">Administración de todo el proceso de compras</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>            
            <b-col lg="8">
              <b-row>                        
                <b-col lg="6">
                  <Widget :configWidget="configWidget" reference="indicator_total_amount_purchase_today" :hasAccess="configWidget.elements.indicatorTotalAmountPurchaseToday" />
                </b-col>            
                <b-col lg="6">                        
                  <Widget :configWidget="configWidget" reference="indicator_total_purchase_today" :hasAccess="configWidget.elements.indicatorTotalPurchaseToday" />
                </b-col>
                <b-col lg="12">                        
                  <Widget :configWidget="configWidget" reference="bar_purchase_months" :hasAccess="configWidget.elements.barPurchaseMonths" />
                </b-col>                
              </b-row>
            </b-col>
          </b-row>
        </b-col> 
        <b-col md="3">
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPurchase()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Compras</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Crear y administrar todos los comprobantes de compras
              </p>
            </b-list-group-item>

            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCurrentsAccounts()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Cuentas Corriente</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Vista de cuentas corrientes de proveedores
              </p>
            </b-list-group-item>
          </b-list-group>
        </b-col>        
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
  //import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.COMPRAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.COMPRAS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            indicatorTotalAmountPurchaseToday: true,
            indicatorTotalPurchaseToday: true,
            barPurchaseMonths: true,            
          }
        },
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.indicatorTotalAmountPurchaseToday = Helper.hasAccessWidget(this.configWidget, 'indicator_total_amount_purchase_today')
      this.configWidget.elements.indicatorTotalPurchaseToday = Helper.hasAccessWidget(this.configWidget, 'indicator_total_purchase_today')
      this.configWidget.elements.barPurchaseMonths = Helper.hasAccessWidget(this.configWidget, 'bar_purchase_months')      
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openPurchase() {
        this.$router.push({ name: 'PurchaseStaffPurchase' })
      }, 
      openCurrentsAccounts() {
        this.$router.push({ name: 'PurchaseStaffCurrentsAccounts' })
      },   
    }    
   
  }
</script>

<style>

</style>
